import { Header } from './widgets/header';

export const Careers = () => (
  <>
    <Header title="CAREERS" />
    <div className="pt-[20px] md:pt-[80px] w-full grid grid-cols-12 ">
      <div className="col-start-2 col-span-10 md:col-start-2 md:col-end-10 ">
        {/* Zaman Çizelgesi */}
        {/* <div className="md:grid md:grid-cols-12 gap-6 pb-[30px] md:pb-[60px]">
          <div
            className="col-span-12  md:col-span-3 text-center 
              md:text-right font-bold text-lg"
          >
            {'AUGUST 2024 - OCTOBER 2024'}
          </div>

          <div className=" bg-ui-blue text-white p-4 rounded-lg relative col-span-9">
            <div className="w-3 left-0 h-full top-0 bg-ui-dark-blue rounded-[15px] absolute"></div>
            <h2 className="pl-[8px] md:pl-[24px] font-bold text-base md:text-xl text-ui-yellow">
              Industrial Automation Intern @ GALBİM Endüstriyel Otomasyon San.
              Tic. Ltd. Şti.
            </h2>
            <p className="pl-[8px] md:pl-[24px] text-xs opacity-85 text-gray-300 mt-1">
              Tekkeköy/Samsun |{' '}
              <a
                href={'https://www.galtek.com.tr/'}
                className="text-ui-green underline"
              >
                Website
              </a>
            </p>
            <p className="pl-[8px] md:pl-[24px] mt-3 mb-3 text-[14px] font-medium text-justify">
              Designed and constructed industrial automation panels, programmed
              PLCs using TIA Portal, developed database applications using
              VBScript and SQL, and engaged in SCADA programming.
            </p>
          </div>
        </div> */}
        <Card
          duration="AUGUST 2024 - OCTOBER 2024"
          title="Industrial Automation Intern @ GALBİM Endüstriyel Otomasyon San. Tic. Ltd. Şti."
          location="Tekkeköy/Samsun"
          link="https://galbim.com.tr/"
          linkText="GALBİM.com"
          description="Designed and constructed industrial automation panels, programmed PLCs using TIA Portal, developed database applications using VBScript and SQL, and engaged in SCADA programming."
        />

        <Card
          duration="OCTOBER 2023 - AUGUST 2024"
          title="Mobile and Backend Development Intern @ Istechsoft Software Technologies"
          location="Atakum/Samsun"
          link="https://www.istechsoft.com/"
          linkText="Istechsoft.com"
          description="Understanding of Flutter GetX, Widgets, local databases, real-time services (Socket.IO), web services (RESTful), Firebase, and MVVM/MVI design patterns. Experience in deploying Android and iOS applications to the market. Proficient in Flutter testing practices. CI/CD for mobile apps using GitHub Actions. Focused on delivering high-quality, high-performance applications to maximize user satisfaction. Continuously staying updated with the latest trends in mobile technologies and committed to lifelong learning. Developing REST API endpoints using Node.js with Express and an ORM architecture with PostgreSQL."
        />

        <Card
          duration="JULY 2022 – SEPTEMBER 2022"
          title="Intern @ GALTEK Mühendislik"
          location="Tekkeköy/Samsun"
          link="https://www.galtek.com.tr/"
          linkText="GALTEK.com"
          description="Organized and designed solar energy system projects with AutoCAD,
              developed 3D models using SketchUp for land analysis, and used
              PVsyst for testing solar panel performance and efficiency."
        />
      </div>
    </div>
  </>
);

const Card = ({
  duration,
  title,
  location,
  link,
  description,
  linkText,
}: {
  duration: string;
  title: string;
  location: string;
  link: string;
  description: string;
  linkText: string;
}) => {
  return (
    <div className="md:grid md:grid-cols-12 gap-6 pb-[30px] md:pb-[60px]">
      {/* duration */}
      <div className="col-span-12  md:col-span-3 text-center md:text-right font-bold text-lg">
        {duration}
      </div>
      {/* Kart */}
      <div className="col-span-9 bg-ui-blue text-white p-4 rounded-lg relative">
        <div className="w-3 left-0 h-full top-0 bg-ui-dark-blue rounded-[15px] absolute"></div>
        <h2 className="pl-[8px] md:pl-[24px] font-bold text-base md:text-xl text-ui-yellow">
          {title}
        </h2>
        <p className="pl-[8px] md:pl-[24px] text-xs opacity-85 text-gray-300 mt-1">
          {location} |{' '}
          <a href={link} className="text-ui-green underline">
            {linkText}
          </a>
        </p>
        <p className="pl-[8px] md:pl-[24px] mt-3 mb-3 text-[14px] font-medium text-justify">
          {description}
        </p>
      </div>
    </div>
  );
};

// ------------------ after responsive for mobile

// const Card = ({
//   duration,
//   title,
//   location,
//   link,
//   description,
//   linkText,
// }: {
//   duration: string;
//   title: string;
//   location: string;
//   link: string;
//   description: string;
//   linkText: string;
// }) => {
//   return (
//     <div className="grid grid-cols-12 gap-6 pb-[60px] ">
//       {/* mt-8 */}
//       <div
//         className="col-span-3
//     text-right font-bold
//     text-lg lg:text-base md:text-sm sm:text-sm "
//       >
//         {duration}
//       </div>
//       {/* Kart */}
//       <div className="col-span-9 bg-ui-blue text-white p-4 rounded-lg relative">
//         <div className="w-3 left-0 h-full top-0 bg-ui-dark-blue rounded-[15px] absolute"></div>
//         <h2 className="pl-[24px] font-bold text-xl text-ui-yellow">
//           {title}
//         </h2>
//         <p className="pl-[24px] text-sm opacity-85 text-gray-300 mt-1">
//           {location} |{' '}
//           <a href={link} className="text-ui-green underline">
//             {linkText}
//           </a>
//         </p>
//         <p className="pl-[24px] mt-3 mb-3 text-[16px] font-medium text-justify">
//           {description}
//         </p>
//       </div>
//     </div>
//   );
// };
/* {description.split(' ').map((e, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 2.1,
                delay: i / 20,
              }}
              key={i}
            >
              {e}{' '}
            </motion.span>
          ))} */

// ---------- before responsive for mobile under

/*
{
  
    
     <div className="mt-8 grid grid-cols-12 gap-6 ">
          <div className="col-span-3 text-right font-bold text-lg">
            AUGUST 2024 - OCTOBER 2024
          </div>

          <div className="col-span-9 bg-ui-blue text-white p-4 rounded-lg relative">
            <div className="w-3 left-0 h-full top-0 bg-ui-dark-blue rounded-[15px] absolute "></div>
            <h2 className="pl-[24px] font-bold text-xl text-ui-yellow">
              Industrial Automation Intern @ GALBİM Endüstriyel Otomasyon San.
              Tic. Ltd. Şti.
            </h2>
            <p className="pl-[24px] text-sm">
              Tekkeköy/Samsun |{' '}
              <a
                href="https://galbim.com.tr/"
                className="text-blue-300 underline"
              >
                https://galbim.com.tr/
              </a>
            </p>
            <p className="pl-[24px] mt-2 text-[16px] font-medium text-justify">
              Designed and constructed industrial automation panels, programmed
              PLCs using TIA Portal, developed database applications using
              VBScript and SQL, and engaged in SCADA programming.
            </p>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-12 gap-6 ">
         
          <div className="col-span-3 text-right font-bold text-lg">
            OCTOBER 2023 - AUGUST 2024
          </div>

          <div className="col-span-9 bg-ui-blue text-white p-4 rounded-lg relative">
            <div className="w-3 left-0 h-full top-0 bg-ui-dark-blue rounded-[15px] absolute "></div>
            <h2 className="pl-[24px] font-bold text-xl text-ui-yellow">
              Mobile and Backend Development Intern @ Istechsoft Software
              Technologies
            </h2>
            <p className="pl-[24px] text-sm">
              Atakum/Samsun |{' '}
              <a
                href="https://www.istechsoft.com/
             "
                className="text-blue-300 underline"
              >
                https://www.istechsoft.com/
              </a>
            </p>
            <p className="pl-[24px] mt-2 text-[16px] font-medium text-justify">
              Understanding of Flutter GetX, Widgets, local databases, real-time
              services (Socket.IO), web services (RESTful), Firebase, and
              MVVM/MVI design patterns. Experience in deploying Android and iOS
              applications to the market. Proficient in Flutter testing
              practices. CI/CD for mobile apps using GitHub Actions.
            </p>
          </div>
        </div>
    
    
    
    
}
*/
