const SocialIcon = ({
  href,
  icon,
  label,
}: {
  href: string;
  icon: JSX.Element;
  label: string;
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-ui-blue h-[80] hover:bg-ui-pink p-3 rounded-[12px] flex items-center justify-center transition-colors duration-300"
      aria-label={label}
    >
      {icon}
    </a>
  );
};

export default SocialIcon;
