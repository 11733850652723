// interface Project {
//   title: string;
//   description: string;
//   link: string;
// }

// const projects: Project[] = [
//   {
//     title: 'Proje 1',
//     description: 'Bu proje hakkında kısa bir açıklama.',
//     link: 'https://proje-linki.com',
//   },
//   {
//     title: 'Proje 2',
//     description: 'Bu proje hakkında kısa bir açıklama.',
//     link: 'https://proje-linki.com',
//   },
//   // Daha fazla proje ekleyin
// ];

// const Projects = () => (
//   <section className="py-16 bg-white">
//     <h2 className="text-3xl font-bold text-center mb-8">Projeler</h2>
//     <div className="flex flex-wrap justify-center gap-8">
//       {projects.map((project, index) => (
//         <div
//           key={index}
//           className="max-w-sm p-4 border rounded-lg shadow hover:shadow-lg transition"
//         >
//           <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
//           <p className="text-gray-700 mb-4">{project.description}</p>
//           <a
//             href={project.link}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="text-blue-500 hover:underline"
//           >
//             Projeye Git
//           </a>
//         </div>
//       ))}
//     </div>
//   </section>
// );

// export default Projects;

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import TeamUp from '../assets/team_up.png';
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
import { Header } from './widgets/header';
import { BackIcon } from '../assets/icon/';

export const Projects = () => {
  return (
    <>
      <Header title="PROJECTS" />
      <div className="pt-[30px] md:pt-[80px]"></div>

      {/* Slider */}
      <div className="grid grid-cols-12 ">
        <button className="custom-prev col-start-0 lg:col-start-2">
          <BackIcon className="fill-ui-pink h-8 w-8 hover:fill-ui-dark-blue" />
        </button>

        <Swiper
          autoplay={{ delay: 3500, disableOnInteraction: false }}
          loop={true}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          color="#152744"
          slidesPerView={1}
          navigation={{
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
          }}
          scrollbar={{ draggable: true }}
          pagination={{
            clickable: true,
          }}
          className="w-full h-[680px] col-start-2 col-span-10  lg:col-start-3 lg:col-end-10 rounded-[12px]"
        >
          {/* İlk Proje */}
          <SwiperSlide>
            <div className="md:grid md:grid-cols-12 flex flex-col items-start bg-ui-yellow overflow-hidden">
              <div className="col-span-6 m-10">
                <h2 className="text-ui-pink font-bold text-[20px] md:text-[36px] text-center pb-[20px]">
                  TEAM UP
                </h2>
                <p className="text-black text-sm md:text-base font-medium leading-relaxed text-justify ">
                  This app is designed to help sports enthusiasts connect and
                  organize activities effortlessly. Users can find opponents,
                  recruit teammates by creating their own teams, or match with
                  rival teams for their favorite sport. Additionally, they can
                  discover sports-specific playing fields to suit their needs.
                  Each user will have a personal profile showcasing their
                  ratings, skills, and other attributes, making it easier to
                  connect with like-minded players.
                </p>
              </div>
              <div className="col-span-6 ">
                <img src={TeamUp} alt="TeamUp" />
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="md:grid md:grid-cols-12 flex flex-col items-start bg-ui-yellow overflow-hidden">
              <div className="col-span-6 m-10">
                <h2 className="text-ui-pink font-bold text-[20px] md:text-[36px] text-center pb-[20px]">
                  TEAM UP
                </h2>
                <p className="text-black text-sm md:text-base font-medium leading-relaxed text-justify ">
                  This app is designed to help sports enthusiasts connect and
                  organize activities effortlessly. Users can find opponents,
                  recruit teammates by creating their own teams, or match with
                  rival teams for their favorite sport.
                </p>
              </div>
              <div className="col-span-6 ">
                <img src={TeamUp} alt="TeamUp" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <button className="custom-next rotate-180 col-start-12 lg:col-start-10 ">
          <BackIcon className="fill-ui-pink h-8 w-8 hover:fill-ui-dark-blue" />
        </button>
      </div>

      {/* <div className="grid grid-cols-12 ">
        <button className="custom-prev col-start-2">
          <BackIcon className="fill-ui-pink h-8 w-8" />
        </button>
        <button className="custom-next rotate-180 col-end-12">
          <BackIcon className="fill-ui-pink h-8 w-8" />
        </button>
      </div> */}

      {/* Alt Simgeler */}
      {/* <div className="grid grid-cols-12">
        <div className="col-start-4 col-end-10 h-[95px] flex justify-center bg-ui-dark-blue rounded-b-[12px]">
          <img src={Web} alt="Web" className="m-7" />
          <img src={AppStore} alt="AppStore" className="m-7" />
          <img src={PlayStore} alt="PlayStore" className="m-7" />
        </div>
      </div> */}
      <div className="pt-[80px]"></div>
    </>
  );
};
