import React, { useState } from 'react';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [errors, setErrors] = useState<{ email?: string; note?: string }>({});

  const validate = () => {
    const newErrors: { email?: string; note?: string } = {};

    // Email Validation
    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email)
    ) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Note/Body Validation
    if (!note) {
      newErrors.note = 'Message is required.';
    } else if (note.length < 10) {
      newErrors.note = 'Message must be at least 10 characters long.';
    } else if (note.length > 500) {
      newErrors.note = 'Message must not exceed 500 characters.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validate()) {
      sendMessage(email, note);
    } else {
      console.log('Validation errors:', errors);
    }
  };

  const sendMessage = async (title: string, body: string) => {
    try {
      const response = await fetch('https://www.kolaydi.com/api/posts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title, body }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Post başarıyla gönderildi:', result);
        alert('Message sent successfully!');
      } else {
        console.error('Bir hata oluştu:', response.statusText);
        alert('Failed to send the message. Try again later.');
      }
    } catch (error) {
      console.error('Hata:', error);
      // alert('An error occurred. Please check your network and try again.');
      alert(error);
    }

    setEmail('');
    setNote('');
  };

  return (
    <form
      className="space-y-4 font-bold text-[20px]"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      {/* Email Address */}
      <div>
        <input
          id="mail"
          name="mail"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validate();
          }}
          className={`w-full bg-white text-black p-3 rounded-md
            border-2
            focus:outline-none focus:ring-2 focus:ring-ui-blue ${
              errors.email ? 'border-red-500' : ''
            }`}
        />
        {errors.email && (
          <p className="text-red-500 text-sm mt-1">{errors.email}</p>
        )}
      </div>

      {/* Note */}
      <div id={'contact'}>
        <textarea
          id="message"
          name="message"
          placeholder="Message..."
          maxLength={500}
          rows={4}
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
            validate();
          }}
          className={`w-full
            border-2
             bg-gray-100 text-gray-800 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-ui-blue resize-none ${
               errors.note ? 'border-red-500' : ''
             }`}
        />
        {errors.note && (
          <p className="text-red-500 text-sm mt-1">{errors.note}</p>
        )}
      </div>

      {/* Submit Button */}

      {/* Gönderme Butonu */}
      <div className="text-right">
        <button
          type="submit"
          className="text-[30px] text-ui-green rounded-md font-bold hover:text-ui-pink transition-all duration-300"
        >
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
/*


import React, { useState } from "react";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState<{ email?: string; note?: string }>({});

  const validate = () => {
    const newErrors: { email?: string; note?: string } = {};

    // Email Validation
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email)
    ) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Note/Body Validation
    if (!note) {
      newErrors.note = "Message is required.";
    } else if (note.length < 10) {
      newErrors.note = "Message must be at least 10 characters long.";
    } else if (note.length > 500) {
      newErrors.note = "Message must not exceed 500 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validate()) {
      // Eğer validation başarılı ise form gönderilir
      console.log("Form gönderiliyor...");
      console.log({ email, note });
      alert("Message sent successfully!");
      // API'ye POST isteği gönderin
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 font-bold text-[20px]"
    >
      
      <div>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`w-full bg-white text-black p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-ui-blue ${
            errors.email ? "border-red-500" : ""
          }`}
        />
        {errors.email && (
          <p className="text-red-500 text-sm mt-1">{errors.email}</p>
        )}
      </div>

     
      <div>
        <textarea
          placeholder="Message..."
          maxLength={500}
          rows={4}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className={`w-full bg-gray-100 text-gray-800 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-ui-blue resize-none ${
            errors.note ? "border-red-500" : ""
          }`}
        />
        {errors.note && (
          <p className="text-red-500 text-sm mt-1">{errors.note}</p>
        )}
      </div>


      <div className="text-right">
        <button
          type="submit"
          className="text-[30px] text-ui-green rounded-md font-bold hover:text-ui-pink transition-all duration-300"
        >
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm;









*/
