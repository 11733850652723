// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <nav className="flex justify-between items-center p-4 bg-gray-800 text-white">
//         <h1 className="text-2xl font-bold">My Portfolio</h1>
//         <ul className="flex space-x-4">
//           <li className="hover:text-gray-400 cursor-pointer">About</li>
//           <li className="hover:text-gray-400 cursor-pointer">Projects</li>
//           <li className="hover:text-gray-400 cursor-pointer">Contact</li>
//         </ul>
//       </nav>
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
import Navbar from './components/Navbar';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import SearchComponent from './components/animation2';
import { Example } from './components/ss';
import { BouncyCardsFeatures } from './components/boc';
import { TextParallaxContentExample } from './components/feature';
const App = () => (
  <div>
    {/* <Navbar />
    <About />
    <Projects />
    <Contact /> */}
    {/* <SearchComponent /> */}
    {/* <Example /> */}
    {/* <BouncyCardsFeatures /> */}
    <TextParallaxContentExample />
  </div>
);

export default App;
