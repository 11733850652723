import { Engine } from '@tsparticles/engine';
import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';

const ParticleBackground = () => {
  const particlesInit = async (engine: any): Promise<void> => {
    await loadSlim(engine);
  };
  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        fullScreen: {
          zIndex: 1,
        },
        particles: {
          color: {
            value: [
              //   '#FFFFFF',
              //'#FFFF00',
              '#00E676',
              //  '03A9F4',
              //   'E040FB',
              //'FFA500',
              'FF7F50',
            ],
          },
          move: {
            direction: 'bottom',
            enable: true,
            outModes: {
              default: 'out',
            },
            size: true,
            speed: {
              min: 1,
              max: 3,
            },
          },
          number: {
            value: 250,
            density: {
              enable: true,
              area: 800,
            },
          },
          opacity: {
            value: 1,
            animation: {
              enable: false,
              startValue: 'max',
              destroy: 'min',
              speed: 0.3,
              sync: true,
            },
          },
          rotate: {
            value: {
              min: 0,
              max: 360,
            },
            direction: 'random',
            move: true,
            animation: {
              enable: true,
              speed: 60,
            },
          },
          tilt: {
            direction: 'random',
            enable: true,
            move: true,
            value: {
              min: 0,
              max: 360,
            },
            animation: {
              enable: true,
              speed: 60,
            },
          },
          shape: {
            type: ['circle', 'square', 'triangle', 'polygon'],
            options: {
              polygon: [
                {
                  sides: 5,
                },
                {
                  sides: 6,
                },
              ],
            },
          },
          size: {
            value: {
              min: 2,
              max: 4,
            },
          },
          roll: {
            darken: {
              enable: true,
              value: 30,
            },
            enlighten: {
              enable: true,
              value: 30,
            },
            enable: true,
            speed: {
              min: 15,
              max: 25,
            },
          },
          wobble: {
            distance: 30,
            enable: true,
            move: true,
            speed: {
              min: -15,
              max: 15,
            },
          },
        },
      }}
    />
  );
};

export default ParticleBackground;
