import { GithubIcon, LinkedInIcon, MailIcon, PhoneIcon } from '../assets/icon';

const socialLinks = [
  {
    href: 'https://github.com/hamdihunturk',
    icon: <GithubIcon className="h-8 w-8  text-ui-yellow" />,
    label: 'GitHub',
  },
  {
    href: 'https://www.linkedin.com/in/hamdi-hunturk-597250197',
    icon: <LinkedInIcon className="h-8 w-8 fill-ui-yellow" />,
    label: 'LinkedIn',
  },
  {
    href: 'mailto:hunturkh@gmail.com',
    icon: <MailIcon className="h-8 w-8 fill-ui-yellow " />, // Mail Icon
    label: 'Mail',
  },
  {
    href: 'tel:+9005522717155',
    icon: <PhoneIcon className="h-8 w-8 fill-ui-yellow " />, // Phone Icon
    label: 'Phone',
  },
];

export default socialLinks;
