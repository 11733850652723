import { useEffect, useState } from 'react';
import socialLinks from '../../data/socialLinks';
import SocialIcon from './social_icon';
import { motion } from 'framer-motion';

const SideBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const footbar = document.getElementById('footbar');
      if (footbar) {
        const footbarTop = footbar.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        // Eğer footbar görünürse ikonları gizle
        if (footbarTop < windowHeight && footbarTop > 0) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <motion.div className="fixed right-0 z-50 h-full items-center hidden md:flex">
      <div
        style={{ opacity: isVisible ? 100 : 0 }}
        className="bg-ui-dark-blue rounded-l-lg p-[15px] flex flex-col space-y-4"
      >
        {socialLinks.map((link, index) => (
          <SocialIcon
            key={index}
            href={link.href}
            icon={link.icon}
            label={link.label}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default SideBar;
