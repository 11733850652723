import React, { ReactNode, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import BackgroundImage from '../assets/background.jpg';
import ProfileCard from './profile';
import Navbar from './Navbar';

export const ParallaxHome = () => {
  return (
    <div className="bg-white">
      <TextParallaxContent key={'Uniquekey1f2s'} />
    </div>
  );
};

const IMG_PADDING = 0;

const TextParallaxContent = ({ children }: { children?: ReactNode }) => {
  return (
    <div>
      <div className="relative h-[150vh]">
        <StickyImage />
        <OverlayCopy />
      </div>
      {children}
    </div>
  );
};

const StickyImage = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['end end', 'end start'],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);
  const opacity1 = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  // const bgColor = useTransform(scrollYProgress, [0.6, 1], ['', '#000000']);
  return (
    <>
      <Navbar key={'UniqueKey1'} opacity={opacity1} />
      <motion.div
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: `calc(100vh - ${IMG_PADDING * 2}px)`,
          top: IMG_PADDING,
          scale,
          opacity,
        }}
        ref={targetRef}
        className="sticky z-0 overflow-hidden rounded-3x0 "
      >
        <motion.div
          className="absolute inset-0 bg-ui-dark-blue"
          style={{
            opacity,
          }}
        />
      </motion.div>
    </>
  );
};

const OverlayCopy = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen
      w-full flex-col items-center justify-center px-4 sm:px-8 md:px-16 lg:px-32"
    >
      <ProfileCard />
    </motion.div>
  );
};
