export const Header = ({ title }: { title: string }) => (
  <div
    id={title.toLowerCase().replaceAll('ı', 'i')}
    className="w-full grid grid-cols-12 h-[60px] "
  >
    <div
      className="col-start-4 col-end-10 md:col-end-8 text-ui-dark-blue border-b-4 border-ui-dark-blue
        font-bold text-4xl flex items-center justify-center 
        h-[60px] w-full"
    >
      {title}
    </div>
  </div>
);
