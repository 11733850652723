import { motion } from 'framer-motion';
import { Careers } from './components/careers';
import { Educations } from './components/education';
import { ParallaxHome } from './components/feature';
import { MyBlogs } from './components/my_blogs';
import { Projects } from './components/Projects';
import SideBar from './components/widgets/contact_sidebar';
import Contact from './components/Contact';
const App = () => (
  <div className="">
    {/* <Navbar /> */}
    <SideBar />
    <ParallaxHome />
    <Careers />
    <div className="pt-[80px]"></div>
    <Educations />
    <div className="pt-[80px]"></div>
    <Projects />
    <div className="md:pt-[80px]"></div>
    <MyBlogs />
    <div className="md:pt-[80px]"></div>
    {/* <div className="overflow-hidden">
      {sections.map((Section, index) => (
        <motion.div
          key={index}
          initial={{
            opacity: 0,
            x: index % 2 === 0 ? 100 : -100,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: false }}
          className="md:pt-[80px]"
        >
          {Section}
        </motion.div>
      ))}
    </div> */}

    <Contact />
  </div>
);

const sections = [<Careers />, <Educations />, <Projects />, <MyBlogs />];

export default App;
