import React, { ReactNode, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import sssa from '../assets/profile.jpg';
import CareersTimeLine from './widgets/Career_timeline';
import EducationTimeline from './widgets/Education_timeline';
import ParticleBackground from './widgets/Particle_Background';
import BackgroundImage from '../assets/background.jpg';
export const TextParallaxContentExample = () => {
  return (
    <div className="bg-white">
      <TextParallaxContent>
        <div className="max-w-4xl mx-auto my-8 p-4 bg-slate-0 bg-slate-0">
          <div className="flex items-center space-x-2 pb-16">
            <div
              className="bg-green-400 text-3xl font-bold font-impact
            text-black px-3 py-1 inline-block rounded"
            >
              CAREERS
            </div>
          </div>
          <CareersTimeLine />
        </div>
        <div className="max-w-4xl mx-auto my-8 p-4 bg-slate-0 bg-slate-0">
          <div className="flex items-center space-x-2 pb-16">
            <div
              className="bg-green-400 text-3xl font-bold font-impact
            text-black px-3 py-1 inline-block rounded"
            >
              EDUCATION
            </div>
          </div>
          <EducationTimeline />
        </div>
      </TextParallaxContent>
    </div>
  );
};

const IMG_PADDING = 0;

const TextParallaxContent = ({ children }: { children?: ReactNode }) => {
  return (
    <div
      style={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage />
        <OverlayCopy />
      </div>
      {children}
    </div>
  );
};

const StickyImage = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['end end', 'end start'],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
        opacity,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3x0"
    >
      <ParticleBackground />
      <motion.div
        className="absolute inset-0 bg-neutral-950/90"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full 
      flex-col items-center justify-center text-white 
      px-4 sm:px-8 md:px-16 lg:px-32"
    >
      <div
        className="flex flex-col items-center md:flex-row md:space-x-8 bg-white 
      p-4 sm:p-8 rounded-lg shadow-md max-w-xl 
      md:max-w-3xl mx-auto text-center md:text-left"
      >
        <img
          src={sssa}
          alt="Kullanıcı"
          className="w-32 h-32 
          sm:w-48 sm:h-48 
          md:w-64 md:h-64 rounded-lg object-cover"
        />
        <div className="mt-4 md:mt-0">
          <div
            className="bg-green-400 text-2xl font-bold font-impact
            text-black px-3 py-1 inline-block rounded"
          >
            Hamdi HUNTÜRK
          </div>
          <p
            className="mt-4 text-gray-800 
          text-sm 
          sm:text-base 
          md:text-2xl 
          font-impact"
          >
            I am currently in my final year of Electrical and Electronics
            Engineering at Ondokuz Mayıs University, with over two years of
            hands-on experience in{' '}
            <strong className="text-orange-400">
              Mobile application development,
            </strong>{' '}
            <strong className="text-orange-400">backend programming</strong> and{' '}
            <strong className="text-orange-400">embedded software.</strong> I
            have a passion for project management and problem-solving, focusing
            on delivering high-quality software solutions. Outside of my
            professional work, I enjoy fitness, exploring philosophical
            concepts, camping, and engaging in various activities that broaden
            my perspective and well-being. I am committed to lifelong learning
            and staying up-to-date with the latest technological advancements.
          </p>
        </div>
      </div>

      <ScrollDownIndicator />
    </motion.div>
  );
};

function ScrollDownIndicator() {
  const aboutRef = useRef<HTMLDivElement>(null);

  const scrollToSection = () => {
    aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <button
        onClick={scrollToSection}
        className="text-red flex flex-col items-center"
      ></button>
      <div className="flex flex-col items-center space-y-2 mt-8">
        <div className="animate-bounce">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-24 w-24 text-green-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        <p className="text-xl font-impact text-white">Scroll down</p>
      </div>
    </div>
  );
}
