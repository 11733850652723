import { Header } from './widgets/header';

export const Educations = () => (
  <>
    <Header title="EDUCATIONS" />
    <div className="pt-[20px] md:pt-[80px] w-full grid grid-cols-12">
      <div className="col-start-2 col-span-10 md:col-start-2 md:col-end-10 ">
        {/* Zaman Çizelgesi */}

        <Card
          duration="2022 – 2024"
          title="Electrical and Electronics Engineering @ Ondokuz Mayıs University"
          description="Currently in my final year, specializing in electrical and electronics engineering. During this period, I gained practical
              experience in mobile applications, backend development, and
              embedded software."
        />

        <Card
          duration="2019 – 2022"
          title="Electrical and Electronics Engineering @ Manisa Celal Bayar University"
          description="Completed foundational studies in electrical and electronics
              engineering, building a strong basis in technical skills and
              theoretical knowledge essential for my field."
        />
      </div>
    </div>
  </>
);

const Card = ({
  duration,
  title,
  description,
}: {
  duration: string;
  title: string;
  description: string;
}) => {
  return (
    <div className="md:grid md:grid-cols-12 gap-6 pb-[30px] md:pb-[60px]">
      {/* mt-8 */}
      <div className="col-span-12  md:col-span-3 text-center md:text-right font-bold text-lg">
        {duration}
      </div>
      {/* Kart */}
      <div className="col-span-9 bg-ui-blue text-white p-4 rounded-lg relative">
        <div className="w-3 left-0 h-full top-0 bg-ui-dark-blue rounded-[15px] absolute"></div>
        <h2 className="pl-[8px] md:pl-[24px] font-bold text-base md:text-xl text-ui-yellow">
          {title}
        </h2>
        <p className="pl-[8px] md:pl-[24px] mt-3 mb-3 text-[14px] font-medium text-justify">
          {description}
          {/* {description.split(' ').map((e, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 1.5,
                delay: i / 20,
              }}
              key={i}
            >
              {e}{' '}
            </motion.span>
          ))} */}
        </p>
      </div>
    </div>
  );
};
