import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

export default function CareersTimeLine() {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: {
            xs: 0.2,
            sm: 0.3,
            md: 0.4,
            lg: 0.4,
          },
        },
      }}
    >
      <TimelineItemComponent
        date="AUGUST 2024 – OCTOBER 2024"
        title="Industrial Automation Intern @ GALBİM Endüstriyel Otomasyon San. Tic. Ltd. Şti"
        location="Tekkeköy/Samsun"
        link="https://galbim.com.tr/"
        description="Designed and constructed industrial automation panels, programmed PLCs using TIA Portal, developed database applications using VBScript and SQL, and engaged in SCADA programming."
      />
      <TimelineItemComponent
        date="OCTOBER 2023 – AUGUST 2024"
        title="Mobile and Backend Development Intern @ Istechsoft Software Technologies"
        location="Atakum/Samsun"
        link="https://www.istechsoft.com/"
        description="Understanding of Flutter GetX, Widgets, local databases, real-time
              services (Socket.IO), web services (RESTful), Firebase, and
              MVVM/MVI design patterns. Experience in deploying Android and iOS
              applications to the market. Proficient in Flutter testing
              practices. CI/CD for mobile apps using GitHub Actions. Focused on
              delivering high-quality, high-performance applications to maximize
              user satisfaction. Continuously staying updated with the latest
              trends in mobile technologies and committed to lifelong learning.
              Developing REST API endpoints using Node.js with Express and an
              ORM architecture with PostgreSQL."
      />
      <TimelineItemComponent
        date="JULY 2022 – SEPTEMB 2022"
        title="Intern @ GALTEK Mühendislik"
        location="Tekkeköy/Samsun"
        link="https://www.galtek.com.tr/"
        description="Organized and designed solar energy system projects with AutoCAD,
              developed 3D models using SketchUp for land analysis, and used
              PVsyst for testing solar panel performance and efficiency."
      />
      {/* <TimelineItem>
        <TimelineOppositeContent
          color="textSecondary"
          fontFamily={'ImpactStone'}
          // fontSize={{ xs: 13, sm: 13, md: 16, lg: 18 }}
        >
          AUGUST 2024 – OCTOBER 2024
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div
            className="
            ml-2 mb-3 
            sm:ml-4 sm:mb-6
            md:ml-8 md:mb-12 
            font-impact"
          >
            <div className=""></div>

            <h3
              className="font-bold 
            text-lg 
            sm:text-lg 
            md:text-2xl"
            >
              Industrial Automation Intern @ GALBİM Endüstriyel Otomasyon San.
              Tic. Ltd. Şti
            </h3>
            <p
              className="text-gray-600 
            text-xs 
            sm:text-xs 
            md:text-lg 
            pt-1"
            >
              Tekkeköy/Samsun |{' '}
              <a
                href="https://galbim.com.tr/"
                className="text-blue-600 hover:underline"
              >
                https://galbim.com.tr/
              </a>
            </p>

            <p
              className="text-gray-700 mt-2 
            text-base 
            sm:text-base 
            md:text-xl 
            pt-2"
            >
              Designed and constructed industrial automation panels, programmed
              PLCs using TIA Portal, developed database applications using
              VBScript and SQL, and engaged in SCADA programming.
            </p>
          </div>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          color="textSecondary"
          fontFamily={'ImpactStone'}
        >
          OCTOBER 2023 – AUGUST 2024
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="mb-12 ml-8 font-impact">
            <div className=""></div>

            <h3 className="text-2xl font-bold">
              Mobile and Backend Development Intern @ Istechsoft Software
              Technologies
            </h3>
            <p className="text-gray-600 text-l  pt-1">
              Atakum/Samsun |{' '}
              <a
                href="https://www.istechsoft.com/"
                className="text-blue-600 hover:underline"
              >
                https://www.istechsoft.com/
              </a>
            </p>

            <p className="text-gray-700 mt-2 text-xl pt-2">
              Understanding of Flutter GetX, Widgets, local databases, real-time
              services (Socket.IO), web services (RESTful), Firebase, and
              MVVM/MVI design patterns. Experience in deploying Android and iOS
              applications to the market. Proficient in Flutter testing
              practices. CI/CD for mobile apps using GitHub Actions. Focused on
              delivering high-quality, high-performance applications to maximize
              user satisfaction. Continuously staying updated with the latest
              trends in mobile technologies and committed to lifelong learning.
              Developing REST API endpoints using Node.js with Express and an
              ORM architecture with PostgreSQL.
            </p>
          </div>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          color="textSecondary"
          fontFamily={'ImpactStone'}
        >
          JULY 2022 – SEPTEMBER 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="mb-12 ml-8">
            <div className=""></div>

            <h3 className="text-2xl font-bold font-impact">
              Intern @ GALTEK Mühendislik
            </h3>
            <p className="text-gray-600 text-l font-impact p-1">
              Tekkeköy/Samsun |{' '}
              <a
                href="https://www.galtek.com.tr/"
                className="text-blue-600 hover:underline"
              >
                https://www.galtek.com.tr/
              </a>
            </p>

            <p className="text-gray-700 mt-2 font-impact text-xl p-2">
              Organized and designed solar energy system projects with AutoCAD,
              developed 3D models using SketchUp for land analysis, and used
              PVsyst for testing solar panel performance and efficiency.
            </p>
          </div>
        </TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}

interface TimelineItemProps {
  date: string;
  title: string;
  location: string;
  link: string;
  description: string;
}

const TimelineItemComponent: React.FC<TimelineItemProps> = ({
  date,
  title,
  location,
  link,
  description,
}) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent color="textSecondary" fontFamily={'ImpactStone'}>
        {date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <div
          className="ml-2 mb-3 
          sm:ml-4 sm:mb-6 
          md:ml-8 md:mb-12 
          font-impact"
        >
          <h3
            className="font-bold 
            text-xl 
            sm:text-xl 
            md:text-2xl"
          >
            {title}
          </h3>
          <p
            className="text-gray-600 
            text-xs 
            sm:text-xs 
            md:text-lg 
            pt-1"
          >
            {location} |{' '}
            <a href={link} className="text-blue-600 hover:underline">
              {link}
            </a>
          </p>
          <p
            className="text-gray-700 mt-2 
            text-base 
            sm:text-base 
            md:text-xl 
            pt-2"
          >
            {description}
          </p>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};
