import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/ibm-plex-mono/100.css'; // Ultra Light
import '@fontsource/ibm-plex-mono/300.css'; // Light
import '@fontsource/ibm-plex-mono/400.css'; // Normal
import '@fontsource/ibm-plex-mono/500.css'; // Medium
import '@fontsource/ibm-plex-mono/700.css'; // Bold

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
