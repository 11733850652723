import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

export default function EducationTimeline() {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: {
            xs: 0.2,
            sm: 0.3,
            md: 0.4,
            lg: 0.4,
          },
        },
      }}
    >
      <TimelineItemComponent
        date={'2022 – 2024'}
        title="Electrical and Electronics Engineering @ Ondokuz Mayıs University"
        description="Currently in my final year, specializing in electrical and electronics engineering. During this period, I gained practical
              experience in mobile applications, backend development, and
              embedded software."
      />
      <TimelineItemComponent
        date={'2019 – 2022'}
        title="Electrical and Electronics Engineering @ Manisa Celal Bayar University"
        description="Completed foundational studies in electrical and electronics
              engineering, building a strong basis in technical skills and
              theoretical knowledge essential for my field."
      />
      {/* <TimelineItem>
        <TimelineOppositeContent
          color="textSecondary"
          fontFamily={'ImpactStone'}
        >
          2022 – 2024
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="mb-12 ml-8 font-impact">
            <div className=""></div>

            <h3 className="text-2xl font-bold">
              Electrical and Electronics Engineering @ Ondokuz Mayıs University
            </h3>

            <p className="text-gray-700 mt-2 text-xl pt-2">
              Currently in my final year, specializing in electrical and
              electronics engineering. During this period, I gained practical
              experience in mobile applications, backend development, and
              embedded software.
            </p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          color="textSecondary"
          fontFamily={'ImpactStone'}
        >
          2019 – 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="mb-12 ml-8 font-impact">
            <div className=""></div>

            <h3 className="text-2xl font-bold">
              Electrical and Electronics Engineering @ Manisa Celal Bayar
              University
            </h3>

            <p className="text-gray-700 mt-2 text-xl pt-2">
              Completed foundational studies in electrical and electronics
              engineering, building a strong basis in technical skills and
              theoretical knowledge essential for my field.
            </p>
          </div>
        </TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}

interface TimelineItemProps {
  date: string;
  title: string;
  description: string;
}

const TimelineItemComponent: React.FC<TimelineItemProps> = ({
  date,
  title,
  description,
}) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent color="textSecondary" fontFamily={'ImpactStone'}>
        {date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <div
          className="ml-2 mb-3 
          sm:ml-4 sm:mb-6 
          md:ml-8 md:mb-12 
          font-impact"
        >
          <h3
            className="font-bold 
            text-xl 
            sm:text-xl 
            md:text-2xl"
          >
            {title}
          </h3>
          <p
            className="text-gray-700 mt-2 
            text-base 
            sm:text-base 
            md:text-xl 
            pt-2"
          >
            {description}
          </p>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};
