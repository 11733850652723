import socialLinks from '../data/socialLinks';
import SocialIcon from './widgets/social_icon';
import ContactForm from './widgets/contact_form';

const Contact = () => {
  return (
    <div
      id="footbar"
      className="bg-ui-dark-blue text-white p-8 pt-10 items-center flex  h-[500px]"
    >
      {/* İkonlar ve Form Alanı */}
      <div
        className="max-w-4xl mx-auto flex flex-col 
      md:flex-row items-start space-y-6 md:space-y-0 
      md:space-x-8"
      >
        {/* Sosyal Medya İkonları */}
        <div className="flex space-x-4 ">
          {socialLinks.map((link, index) => (
            <SocialIcon
              key={index}
              href={link.href}
              icon={link.icon}
              label={link.label}
            />
          ))}
        </div>
        <div className="hidden md:flex border-2 border-ui-blue h-[460px]"></div>
        {/* İletişim Formu */}
        <div className="flex-1 ">
          <h2 className="text-[30px] font-bold mb-4">Contact Me</h2>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;

/*
src/
├── assets/               # Statik dosyalar (resimler, ikonlar, fontlar, vs.)
│   ├── icons/
│   ├── images/
│   └── styles/
│       └── global.css    # Global CSS dosyası
├── components/           # Tekrar kullanılabilir bileşenler
│   ├── Button/
│   │   ├── Button.tsx
│   │   ├── Button.test.tsx
│   │   └── Button.module.css
│   ├── Header/
│   │   ├── Header.tsx
│   │   └── Header.test.tsx
│   └── ...
├── config/               # Proje yapılandırma dosyaları (API endpointler, tema ayarları, vb.)
│   ├── apiConfig.ts
│   └── themeConfig.ts
├── context/              # React Context API için oluşturulan dosyalar
│   ├── AuthContext.tsx
│   └── ThemeContext.tsx
├── data/                 # Statik veri dosyaları (JSON, dizi ve nesneler)
│   ├── socialLinks.ts
│   └── dummyData.ts
├── hooks/                # Özel React hook dosyaları
│   ├── useAuth.ts
│   └── useDebounce.ts
├── layouts/              # Genel layout dosyaları (Navbar, Footer, vs.)
│   ├── MainLayout.tsx
│   ├── AuthLayout.tsx
│   └── ...
├── pages/                # Sayfalar
│   ├── Home/
│   │   ├── Home.tsx
│   │   └── Home.test.tsx
│   ├── About/
│   │   ├── About.tsx
│   │   └── About.module.css
│   └── ...
├── routes/               # React Router ile kullanılan rotalar
│   └── AppRoutes.tsx
├── services/             # API çağrıları ve diğer servis dosyaları
│   ├── authService.ts
│   └── userService.ts
├── state/                # Redux/Context/State Management
│   ├── store.ts          # Redux store
│   ├── slices/
│   │   ├── authSlice.ts
│   │   └── userSlice.ts
│   └── ...
├── types/                # Tip tanımları
│   ├── api.d.ts          # API tipleri
│   ├── components.d.ts   # Bileşen tipleri
│   └── models.d.ts       # Genel model tipleri
├── utils/                # Yardımcı fonksiyonlar
│   ├── formatDate.ts
│   ├── validateEmail.ts
│   └── ...
├── App.tsx               # Ana uygulama bileşeni
├── index.tsx             # React uygulamasının giriş dosyası
└── vite-env.d.ts         # TypeScript için Vite ortam tipi

*/
