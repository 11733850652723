import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import NavbarDropdown from './widgets/navbar_dropdown';

const Navbar = ({ opacity }: { opacity: any }) => {
  // return (
  //   <motion.nav
  //     style={{ background: '', opacity: opacity }}
  //     className=" text-white px-4 py-2 fixed w-full z-10 top-0 bg-custom-purple"
  //   >
  //     {/*shadow-lg*/}
  //     <div className="container mx-auto flex justify-between items-center w-10/12 h-20">
  //       {/* Logo veya Başlık */}
  //       <div className="text-5xl font-medium">
  //         <a href="#home">Hamdi Huntürk</a>
  //       </div>
  //       {/* Butonlar */}
  //       <div className="space-x-5 text-2xl font-medium">
  //         <LinkCatagory to="contact" title="Contact" />
  //         <LinkCatagory to="contact" title="Contact" />
  //         <LinkCatagory to="blogs" title="Blogs" />
  //         <LinkCatagory to="blogs" title="Blogs" />
  //       </div>
  //     </div>
  //   </motion.nav>
  // );

  // return (
  //     <motion.nav
  //       style={{ background: '', opacity: opacity }}
  //       className="bg-ui-dark-blue text-white py-4 z-10 fixed w-full "
  //     >
  //       <div className="w-full mx-auto flex justify-around items-baseline">
  //         {/* Sol Kısım */}
  //         <h1 className="text-[46px] font-bold">Hamdi Huntürk</h1>

  //         {/* Sağ Kısım */}
  //         <ul className="flex space-x-8  text-[19px] font-bold">
  //           <LinkCatagory to="Careers" title="Careers" />
  //           <LinkCatagory to="Education" title="Education" />
  //           <LinkCatagory to="Projects" title="Projects" />
  //           <LinkCatagory to="My Blogs" title="My Blogs" />
  //         </ul>
  //       </div>
  //     </motion.nav>
  // );

  return (
    <motion.nav
      style={{ opacity: opacity }}
      className="bg-ui-dark-blue text-white py-4 z-10 fixed w-full"
    >
      <div
        className="grid grid-cols-12 grid-rows-1 gap-0 w-full 
      mx-auto justify-around 
      md:items-baseline 
      items-center"
      >
        <div className="nv-0 font-bold text-start md:text-end">
          Hamdi Huntürk
        </div>
        {/* <ul className="flex space-x-20  text-[19px] font-bold col-start-8 col-end-11">
          <LinkCatagory title="Careers" />
          <LinkCatagory title="Education" />
          <LinkCatagory title="Projects" />
          <LinkCatagory title="My Blogs" />
        </ul> 
        */}

        <div className="flex md:hidden col-start-10 col-end-12 justify-self-end">
          <NavbarDropdown />
        </div>

        <LinkCatagory title="Careers" className="col-start-8" />
        <LinkCatagory title="Educations" className="col-start-9" />
        <LinkCatagory title="Projects" className="col-start-10" />
        <LinkCatagory title="My Blogs" className="col-start-11 " />
      </div>
    </motion.nav>
  );
};

export default Navbar;

const LinkCatagory = ({
  title,
  className,
}: {
  className?: string;
  title: string;
}) => {
  const combinedClassName =
    className +
    ` bg-transparent
      font-bold
      nv-1
      cursor-pointer
      group transition-all duration-500 ease-in-out
      hidden md:flex
    `;

  return (
    <Link
      to={title.toLowerCase()}
      smooth={true}
      offset={-150}
      duration={500}
      className={combinedClassName}
    >
      <span
        className="
          bg-left-bottom
          bg-gradient-to-r
          from-white
          to-white
          bg-[length:0%_2px]
          group-hover:bg-[length:100%_2px]
          bg-no-repeat
          rounded-bl-[2px]
          rounded-br-[2px]
          transition-all
          duration-500
          ease-in-out
        "
      >
        {title}
      </span>
    </Link>
  );
};

/* <div className="md:hidden col-start-10">
  <button
    onClick={toggleMenu}
    className="text-gray-200 
            hover:text-white f
            ocus:outline-none focus:ring-2 
            focus:ring-inset focus:ring-white"
  >
    <svg
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
      />
    </svg>
  </button>
</div>;
{
  isOpen && (
    <div className="md:hidden flex">
      <div className="px-2 pt-2 pb-3 space-y-1">
        <a href="#" className="block hover:bg-gray-700 px-3 py-2 rounded-md">
          Home
        </a>
        <a href="#" className="block hover:bg-gray-700 px-3 py-2 rounded-md">
          About
        </a>
        <a href="#" className="block hover:bg-gray-700 px-3 py-2 rounded-md">
          Services
        </a>
        <a href="#" className="block hover:bg-gray-700 px-3 py-2 rounded-md">
          Contact
        </a>
      </div>
    </div>
  );
} */
