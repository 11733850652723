import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-scroll';

const NavbarDropdown = () => {
  const [open, setOpen] = useState(false);

  const showFlyout = DropdownContent() && open;

  return (
    <div
      key={'UniqueKey21'}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <button
        className=" items-center md:hidden"
        onClick={() => setOpen(!open)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-7 h-7 justify-self-end"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d={open ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
          />
        </svg>
      </button>
      {/* <a className="relative text-white">
        {'children'}
        <span
          style={{
            transform: showFlyout ? 'scaleX(1)' : 'scaleX(0)',
          }}
          className="absolute -bottom-2 -left-2 
          -right-2 h-1 origin-left scale-x-0 rounded-full
           bg-indigo-300 transition-transform 
           duration-300 ease-out"
        />
      </a> */}
      <AnimatePresence key={'UniqueKey2'}>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: '-65%' }}
            transition={{ duration: 0.3, ease: 'easeOut' }}
            className="absolute
            top-[50px] bg-ui-blue text-white"
          >
            <div className="absolute -top-6 left-0 right-0 h-6" />
            <div
              className="absolute right-6 top-0 h-4 
              w-4 
              -translate-x-1/2 
              -translate-y-1/2 
              rotate-45 bg-ui-blue"
            />
            <DropdownContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const DropdownContent = () => {
  const title = ['Careers', 'Educations', 'Projects', 'My Blogs', 'Contact'];
  return (
    <div className="w-40 bg-ui-blue p-6 shadow-xl font-bold text-start">
      <div className="mb-3 space-y-2">
        {title.map((link, index) => (
          <Link
            key={index} // Benzersiz bir key ekleniyor
            id={index.toString()}
            to={link.toLowerCase()}
            smooth={true}
            offset={-150}
            duration={500}
            className="block" // CSS sınıfı eklenerek stil tutarlılığı sağlanır
          >
            {link}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NavbarDropdown;
