import { Header } from './widgets/header';

export const MyBlogs = () => (
  <>
    <Header title="MY BLOGS" />
    <div className="pt-[30px] md:pt-[80px] w-full grid grid-cols-12"></div>
    {/* <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
      {blogData.map((blog, index) => (
        <div
          key={index}
          className="border border-gray-300 rounded-lg p-6 shadow-md"
        >
          <h2 className="text-pink-600 font-bold text-xl mb-4">{blog.title}</h2>
          <p className="text-gray-700 text-sm mb-4">
            {blog.content}{' '}
            <a href={blog.link} className="text-pink-500 font-bold underline">
              More...
            </a>
          </p>
        </div>
      ))}
    </div> */}
    {blogData.map((blog, index) => (
      <div key={index} className="w-full grid grid-cols-12">
        <div
          className="
          
          md:h-[300px]
          col-span-12 
          max-sm:flex-col
          max-sm:justify-start
        md:col-start-3 md:col-end-10 flex justify-evenly border-b-4 border-ui-dark-blue"
        >
          <h2
            className="text-ui-pink font-bold text-base md:text-2xl 
          max-sm:mr-4
          ml-4 my-2 md:my-4 md:w-[800px]"
          >
            {blog.title}
          </h2>
          <div className="hidden md:flex h-full border-2 border-ui-dark-blue"></div>
          <div className="flex flex-wrap md:hidden gap-2 max-sm:px-4 text-xs text-black">
            {blog.tags.map((blog, index) => (
              <div key={index} className="bg-ui-green px-2 py-1 rounded-md">
                {blog}
              </div>
            ))}
            {/* <div className="bg-ui-green px-2 py-1 rounded-md">
              Ruby on Rails
            </div>
            <div className="bg-ui-green px-2 py-1 rounded-md">Node.js</div> */}
          </div>
          <p className="text-xs md:text-base mx-4 overflow-hidden md:ml-10 font-medium text-justify my-4">
            {blog.content}
            {/* {blog.content.split(' ').map((e, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: i / 20,
                }}
                key={i}
              >
                {e}{' '}
              </motion.span>
            ))} */}
            {/* href={blog.link} */}
            <a className="text-ui-pink font-bold underline ">More...</a>
          </p>
        </div>
      </div>
    ))}
    <div className="pt-[80px]"></div>
  </>
);
const blogData = [
  {
    title: 'Why Node.js is Faster than Rails?',
    content:
      "Node.js is faster than Rails due to its event-driven, non-blocking I/O model, allowing it to handle multiple requests simultaneously. Powered by Google’s V8 engine, Node.js executes JavaScript efficiently, making it ideal for high-concurrency applications. Unlike Rails' multi-threaded and monolithic approach, Node.js excels in lightweight tasks and real-time interactions, offering better speed and scalability for modern web development.",
    link: '#',
    tags: ['Ruby on rails', 'Node.js'],
  },
  {
    title: 'Why Microservices Are the Future of Software Development',
    content:
      'Microservices architecture is transforming how software is built and scaled. Unlike monolithic applications, microservices divide an application into smaller, independent services that communicate through APIs. This approach enhances scalability, as each service can be scaled individually based on demand. It also improves fault isolation—if one service fails, others remain unaffected. Moreover, microservices promote flexibility, allowing teams to use different programming languages or tools for different services. This makes it easier to adopt new technologies and streamline development workflows. As businesses prioritize agility and resilience, microservices are emerging as the go-to solution for building future-ready applications.',
    link: '#',
    tags: ['Backend'],
  },
  {
    title: 'The Importance of PLCs in Industrial Automation',
    content:
      'Programmable Logic Controllers (PLCs) are the backbone of industrial automation, providing reliable and efficient control for machinery and processes. These ruggedized computers are designed to withstand harsh industrial environments while offering high-speed processing and real-time response. PLCs excel at automating repetitive tasks such as controlling conveyor systems, assembly lines, and robotic arms. Their modular design allows easy integration with sensors, actuators, and communication networks, enabling seamless interaction with other devices in an automation ecosystem.',
    link: '#',
    tags: ['Industrial Automation', 'PLC'],
  },
];
